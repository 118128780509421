.carousel {
  opacity: 0.5;
}

/* .carousel-caption {
  bottom: 30% !important;
}
.carousel-caption h1 {
  font-size: 50px;
  font-weight: 800;
  color: white !important;
  margin-bottom: 20px;
}
.carousel-caption a {
  text-decoration: none;
  color: white;
  background: #a61151;
  padding: 15px 25px;
  transition: 0.3s;
}
.carousel-caption a:hover {
  background-color: #940e48;
} */
