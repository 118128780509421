.proCard img {
  object-fit: cover;
  border-radius: 0;
}

.proCard .card-body {
  position: relative;
}

.proCard .proCardDate {
  display: inline-block;
  background-color: #a61151;
  color: white;
  padding: 7px 18px;
  position: absolute;
  top: -20px;
}

.proCard {
  border: none !important;
  border-radius: 0 !important;
  overflow: hidden;
}

.proCard .proImg {
  overflow: hidden;
}
.proCard .proImg img {
  transition: 1.3s;
}
.proCard:hover img {
  transform: scale(1.3);
}

.proCard {
  margin-bottom: 25px;
}

.proCard p {
  color: #1c1e21;
}

.proCard h6 {
  color: white !important;
}
