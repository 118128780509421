.objSection .card {
  border-radius: 0;
  background: transparent;
  border: none;
  transition: all 0.2s;
}

.objSection .card:hover {
  background: #17181a;
}
.objSection .card:hover .teko {
  color: #a61151;
}

.objSection .card .teko {
  color: rgb(245, 245, 245);
}

.objSection .card p {
  color: rgb(214, 214, 214);
}

.objSection h5 {
  color: white !important;
}

@media only screen and (max-width: 500px) {
  .objSection .card {
    text-align: center;
  }
}
@media only screen and (max-width: 799px) {
  .objSection .card {
    margin: 0 auto;
  }
}
