.footerSection {
  border-top: 2px solid #a61151;
  border-bottom: 2px solid #a61151;
}

.footerSection h3 {
  color: #a61151;
  border-bottom: 1px solid #a61151;
  padding-bottom: 10px;
  margin-bottom: 1em;
}

.footerSection p {
  color: white;
}

.footerSection .footerLinks {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footerSection .footerLinks a {
  color: white;
  text-decoration: none;
  transition: 0.2s;
}

.footerSection .footerLinks a:hover {
  color: #a61151;
}

.socialIcons i {
  font-size: 20px;
  color: white;
  transition: 0.3s;
}
.socialIcons i:hover {
  color: #a61151;
}

.bottomFooter p {
  color: white;
  font-size: 0.9em;
}

@media only screen and (max-width: 500px) {
  .bottomFooter .term {
    display: block !important;
  }
}
