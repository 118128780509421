.achievementSection {
  border-top: 1px solid #a61151;
  border-bottom: 1px solid #a61151;
}

.achievementSection span {
  color: rgb(235, 235, 235);
}

.achievementSection h3 {
  color: #a61151;
}

.achievementSection h2 {
  color: #a61151;
  font-weight: 700;
  font-size: 50px !important;
}

.achievementSection .miniLogo {
  width: 120px;
}

.JoinUsbtn {
  background-color: #a61151;
  border: none;
  color: white;
  padding: 15px 20px;
}

.JoinUsbtn:hover {
  background-color: #940e48;
}

input[type="text"],
select,
textarea,
input[type="email"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  color: black !important;
}

input[type="submit"] {
  background-color: #a61151;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #940e48;
}

.modal-title {
  color: #a61151;
  font-size: 25px !important;
  font-weight: 700;
}

@media only screen and (max-width: 500px) {
  .clubLogo {
    text-align: center;
  }
  .achieve {
    text-align: center;
  }
  .joinUs {
    flex-direction: column;
    gap: 15px !important;
    text-align: center;
  }
}
@media only screen and (max-width: 799px) {
  .joinUs {
    margin-top: 1em;
  }
}
